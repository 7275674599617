<template>
	<div>
		<!-- 头部导航 -->
		<el-affix :z-index='2001'>
		  <nav-bar></nav-bar>
		</el-affix>
		<!-- 工具栏 -->
		<tool-box></tool-box>
		
		<!-- 订单列表 -->
		<div class='order-wrap container'
			element-loading-text="无需支付"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.8)"
			v-loading.fullscreen.lock="fullscreenLoading">
			<div class='wrap-content'>
				<el-card class="box-card">
					<template #header>
						<div class="card-header">
							<span>订单号</span>
							<span class='order-num'>{{orderInfo.order_no}}</span>
						</div>
					</template>
					<div class="order-con">
						<el-result icon="success" title="订单提交成功" >
							<template #extra>
								<div class='order-money'>
									<span>应付金额：</span>
									<span class='money'>￥{{orderInfo.sale_money}}</span>
								</div>
							</template>
						</el-result>
					</div>
				</el-card>
				
				<el-card class="box-card pay-style" >
					<template #header>
						<div class="card-header style-title">
							<span>请选择支付方式</span>
						</div>
					</template>
					<div class="pay-con">
						<div class='pay-title' @click='aliPayBtn()'>
						  <svg-icon 
							  icon-class='zhifubao' 
							  class='tab-icon'></svg-icon>
						  <span>支付宝</span>
						</div>
						<div class='pay-title' @click='wxPayBtn()'>
						  <svg-icon 
							  icon-class='weixinzhifu' 
							  class='tab-icon'></svg-icon>
						  <span>微 信</span>
						</div>
					</div>
				</el-card>
			</div>
		</div>
		
		<!-- 支付宝支付对话框 -->
		<el-dialog
			v-model="dialogAliPayment"
			width='25%'
			:before-close="handleAlipayClose"
		>
			<template #title>
				<div class='alipay-title'>
					<svg-icon icon-class='zhifubao' class='ali-icon'></svg-icon>
					<span>支付宝</span>
				</div>
			</template>
			<el-result 
				icon="warning" 
				title="订单支付中..." 
				subTitle="请确认支付情况" >
			  <template #extra>
				<el-button
					@click="handleAlipayClose" 
					type="primary" 
					size="medium">完成支付</el-button>
			  </template>
			</el-result>
		</el-dialog>
		
		
		<!-- 微信支付 -->
		<el-dialog
			v-model="dialogWxPayment"
			width='25%'
			:before-close="handlewxPayClose"
		>
			<template #title>
				<div class='wxpay-title'>
					<svg-icon icon-class='weixinzhifu' class='wx-icon'></svg-icon>
					<span>微信</span>
				</div>
			</template>
			<div class='wx-code'>
			  <div id="qrcode" ref="qrCode"></div>
			</div>
			<template #footer>
				<div class="wx-footer">
					<el-button 
						@click="handlewxPayClose"
						class='wx-btn'
						type='danger'>出现问题</el-button>
					<el-button
						class='wx-btn'
						@click="handlewxPayClose" 
						type='success'>支付成功</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- footer -->
		<web-footer></web-footer>
	</div>
</template>

<script>
	import NavBar from '@/components/page/NavBar'
	import ToolBox from '@/components/toolbox'
	import WebFooter from '@/components/page/WebFooter'
	import {getSessionStorage,getLocalStorage} from '@/utils/auth'	
	import {aliPay,aliPayCom,wxPay,wxPayState} from '@/api/order'
	import QRCode from 'qrcodejs2'
	export default{
		name:'orderInfo',
		props:['orderid'],
		data(){
			return{
				dialogAliPayment:false,//支付宝对话框
				dialogWxPayment:false, //微信对话框
				aliPayCompleted:false,//支付宝支付成功
				wxPayCompleted:false,//微信支付成功
				
				fullscreenLoading:false,
				orderInfo:{
					sale_money:'',
					order_no:''
				}
			}
		},
		components:{
			NavBar,
			ToolBox,
			WebFooter
		},
		mounted() {
			this.orderInfo = getSessionStorage('orderInfo');
			if(this.orderInfo.sale_money==0){//无需支付，直接跳装支付成功页面
			    this.fullscreenLoading = true;
			    setTimeout(() => {
			      this.fullscreenLoading = false;
			      this.$router.push({
			        path:'/paysuccess'
			      });
			    }, 2000);
			}
		},
		methods:{
			aliPayBtn(){//支付宝支付
				aliPay({
					paytype:4,
					title:this.orderInfo.title.substring(0,10),
					order_no:this.orderInfo.order_no,
					money:this.orderInfo.sale_money,
					openid:"",
					appid:""
				}).then(res=>{
					console.log(res)
					let payhtml = res.data
					let routerData = this.$router.resolve({
						path:'/orderalipay',
						query: {payData: payhtml},
					});
					window.open(routerData.href, '_ blank');
					// 支付宝提示对话框
					this.dialogAliPayment = true;
				});
			},
			handleAlipayClose(){//判断支付宝是否支付
				let _this = this;
				aliPayCom({
					order_no:this.orderInfo.order_no,
				}).then(res=>{
					if(res.code==1&&res.data.trxstatus == "0000"){
						let _this = this;
						this.$notify({
							title: '支付成功',
							message: '支付成功',
							type: 'success',
							duration:1500,
							showClose:false,
							onClose:function(){
								_this.$router.push({
									path:'/paysuccess'
								})
							}
						})
					}else{
						this.$notify.error({
							title: '支付失败',
							message: '请重新支付',
							duration:1500,
							showClose:false
						});
					}
					this.dialogAliPayment = false;
				})
			},
			wxPayBtn(){//微信按钮
				wxPay({
					paytype:1,
					title:this.orderInfo.title.substring(0,10),
					order_no:this.orderInfo.order_no,
					money:this.orderInfo.sale_money*100
				}).then(res=>{
					let payhtml = res.data
					let routerData = this.$router.resolve({
						path:'/orderalipay',
						query: {payData: payhtml},
					});
					window.open(routerData.href, '_ blank');
					// 支付宝提示对话框
					this.dialogAliPayment = true;
					
				})
			},
			handlewxPayClose(){//微信支付成功
				wxPayState({
					order_no:this.orderInfo.order_no,
				}).then(res=>{
					if(res.code==1&&res.data.trxstatus == "0000"){
						this.dialogWxPayment = false;
						this.$refs.qrCode.innerHTML = '';
						this.$notify({
							title: '支付成功',
							message: '支付成功',
							type: 'success',
							duration:1500,
							showClose:false,
							onClose:function(){
								_this.$router.push({
									path:'/paysuccess'
								})
							}
						});
						  
					}else{
						this.dialogWxPayment = false;
						this.$refs.qrCode.innerHTML = '';
						this.$notify.error({
							title: '支付失败',
							message: '请重新支付',
							duration:1500,
							showClose:false
						});
					}
			  });
			}
		}
	}
</script>

<style scoped lang="scss">
	.order-wrap{
		margin:20px auto;
		.box-card{
			padding:30px 20px;
		}
		.card-header{
		  display: flex;
		  flex-direction: row;
		  align-items: center;
		  margin-bottom:10px;
		  padding-left:10px;
		  border-left:3px solid $theme-color;
		  .order-num{
			  margin-left:10px;
		  }
		}
		.order-con{
		  display: flex;
		  flex-direction: column;
		  align-items: center;
		  .order-money{
				font-size:16px;
				.money{
					color:$price-color;
					font-size:26px;
				}
		  }
		}
		.pay-style{
			margin-top:20px;
			.style-title{
				font-size:18px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding-left:10px;
				margin-bottom:10px;
				border-left:3px solid $theme-color;
				font-weight: bold;
			}
			.pay-con{
				margin-top:10px;
				display: flex;
				flex-direction: row;
				.pay-title{
					display: flex;
					flex-direction: row;
					align-items: center;
					border:1px solid #ccc;
					width:150px;
					height: 40px;
					margin-right:20px;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					background-color: #eee;
					font-size:16px;
					.tab-icon{
						margin-right:10px;
						font-size:24px;
					}
				}
				.pay-title:hover{
					background-color: $theme-color;
					color:#fff;
					border:1px solid $theme-color;
				}
			}
		}
		
		
	}
	.alipay-title{
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size:16px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ccc;
		.ali-icon{
			font-size:20px;
			margin-right:10px;
		}
	}
	
	.wxpay-title{
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size:16px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ccc;
		.wx-icon{
			font-size:20px;
			margin-right:10px;
		}
	}
	.wx-code{
		padding:20px 0px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.wx-footer{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		.wx-btn{
			margin:0px 20px;
		}
	}
</style>
